import React from "react";
import { StepsData } from "../DocumentationData";

const StepComponent = ({data,index}) => {
  
  return (
    <section className="section">
      <div className="header">
        <h1>{data?.name}</h1>
        <div className="intersection w-full"></div>
        <p className="description">{data?.description}</p>
        {
          data?.name ==="Step 4" && <p className="description">Note: Account deletion will include all your private data, user data and everything associated with your account as per our privacy policies. Once your account has been deleted, this cannot be undone.</p>
        }
      </div>

      <div className="imgPart">
        {
          index>1 ? <img className="ScImgGt3" src={data.imgSrc}/> :  <img className="max-w-[340px]" src={data?.imgSrc} alt="..." />
        }
       
      </div>
    </section>
  );
};

export default StepComponent;
