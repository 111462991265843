import './App.css';
import HomeComponent from './components/HomeComponent';

function App() {
  return (
    <div className="App">
      <HomeComponent/>
    </div>
  );
}

export default App;
