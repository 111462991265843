import img1 from "../src/assets/ImgStep1.png"
import img2 from "../src/assets/ImgStep2.png"
import img3 from "../src/assets/ImgStep3.png"
import img4 from "../src/assets/ImgStep4.png"
import img5 from "../src/assets/ImgStep5.png"


export const StepsData=[
    {
        name:"Step 1",
         description:"Click on privacy settings",
         imgSrc:img1
    },
    {
        name:"Step 2",
        description:"Choose between deactivate account or delete account permanentaly",
        imgSrc:img2

    },
    {
        name:"Step 3",
        description:"If clicked on deactivate account: the account will be deactivated within 25 hours. Just remember your account details and reactivate by simply signing back in anytime.",
        imgSrc:img3

    },
    {
        name:"Step 4",
        description:"If clicked on delete account permanently: You will have 45 days to recover account & account will be deleted permanently after that.",
        imgSrc:img4
    },
    {
        name:"Step 5",
        description:"After clicking on confirm for account deletion, you will get a final message as shown below",
        imgSrc:img5
    }
]