import * as yup from 'yup';


// Form validation is Used in HeroForm Section and Form reuseable
export const validationSchemaForm = yup.object({
    phone: yup
        .string("Enter your phone")
        .min(10, "Phone number should be 10 digit")
        .matches(/^[6-9][0-9]*$/, "Enter valid mobile number")
        .required("Phone Number is required")
});
