import axios from "axios";


const newAxiosClientInterceptors = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});



export default newAxiosClientInterceptors;







