import Box from "@mui/material/Box";
import "../Reusable/Reusable.css"
const AccountDeletion = ({handleCancel,deletePartner,userName,userEmail}) => {

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "1px solid #fff",
    boxShadow: 24,
    padding: "20px 32px",
    width:"364px",
    '@media (max-width:370px)': {
      width: "320px",
      padding:"20px 22px"
  }
  };

  return (
    <>
      <Box  className="whiteModal" sx={style}>
        <div className="deletionContainer">
          <h3 className="deletionHeader">Account Deletion</h3>
          <svg
            onClick={handleCancel}
            className="cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
              fill="black"
            />
          </svg>
        </div>

        <div className="deletAccountDetails">
            <p className="name">Name: {userName|| '-'}</p>
            <p className="email">Your Email ID: {userEmail || '-'}</p>
            <p className="desc mt-[10px] mb-[20px]" >
                 After clicking on confirm your delete request will be raised. Kindly verify your name and email, before proceeding. 
            </p>

            <div className="deletAccountButtons">
                <button className="cancel" onClick={handleCancel}>Cancel</button>
                <button className="confirm" onClick={deletePartner}>Confirm</button>
            </div>

        </div>
      </Box>
    </>
  );
};

export default AccountDeletion;
