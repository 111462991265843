import React, { useEffect, useRef, useState } from "react";
import StepComponent from "../Reusable/StepComponent";
import { StepsData } from "../DocumentationData";
import logo from "../assets/CendrolPartnerImg.png";
import { useFormik } from "formik";
import OTPInput from "react-otp-input";
import { validationSchemaForm } from "../Schema/Validation";
import CustomTimer from "../Reusable/CustomTimer";
import Modal from "@mui/material/Modal";
import AccountDeletion from "../Reusable/AccountDeletion";
import UserNotFound from "../Reusable/UserNotFound";
import newAxiosClientInterceptors from "../config/newAxiosClientInterceptors";
//toast
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Reusable/Loader";
import RequestAccepted from "../Reusable/RequestAccepted";


var invalidChars = ["-", "+", "e", "."];
const HomeComponent = () => {
  const inputRef = useRef();

  const headers = {
    "Content-Type": "application/json",
    authkey: process.env.REACT_APP_AUTH_KEY,
    usertype: process.env.REACT_APP_USER_TYPE,
  };
  const [openOTP, setOpenOTP] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState("0:00");
  const [startTimers, setStartTimer] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [isUserNotFound, setIsUserNotFound] = useState(false);
  const [toSendId, setToSendId] = useState("");
  const [otpVerifiedError, setOtpVerifiedError] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [showRemainOtp, setShowRemainOtp] = useState(false);
  const [requestAccepted,setRequestAccepted]=useState(false)
 

  useEffect(() => {
    if (!localStorage.getItem("otp_remain")) {
      localStorage.setItem("otp_remain", 3);
      localStorage.setItem("countdownStart", null);
    }
  }, []);

  const handleCancel = () => {
    setModalOpen(false);
    setStartTimer(startTimers + 1);
  };

  const userNotFoundModal=()=>{
    setIsUserNotFound(false)
    setIsLoading(false)
  }
  const handleModal = () => {
    if (otp.length === 4) {
      verifyOtp();
    } else {
      setOtpVerifiedError(true);
    }
  };

  const requestAcceptModal=()=>{
    setRequestAccepted(false)
    setIsLoading(false)
  }

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: validationSchemaForm,
    onSubmit: () => {
      setIsLoading(true);
      setOtpVerifiedError(false)
      const countdownStart = localStorage.getItem("countdownStart");
      const tryMinute = 5;

      if (countdownStart !== "null") {
        var timeStart = countdownStart;
        var timeNow = new Date().getTime();
        if (timeNow - timeStart > 300000){        //300000ms=5min
          localStorage.setItem("otp_remain", 3);
          localStorage.setItem("countdownStart", null);
        }
      }

      if (localStorage.getItem("otp_remain") > "0") {
        getOtp();
      } else {
        localStorage.getItem("countdownStart") === "null" && localStorage.setItem("countdownStart", new Date().getTime());
        toast["warning"]( `Try after ${ tryMinute - Math.floor((timeNow - timeStart) / 60000) || tryMinute} min`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setIsLoading(false)
        setOpenOTP(false)
        setShowRemainOtp(false)
      }
    },
  });

  const getOtp = () => {
    newAxiosClientInterceptors
      .post(
        `/send-sms-otp-mobile`,
        {
          mobile: "+91" + formik.values.phone,
        },
        { headers }
      )
      .then((res) => {
        toast["success"](res?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setOtp("");
        setOpenOTP(true);
        setStartTimer(startTimers + 1);
        setTimer("2:00");
        setIsLoading(false);
        if (localStorage.getItem("otp_remain") > 0) {
          localStorage.setItem(
            "otp_remain",
            localStorage.getItem("otp_remain") - 1
          );
          setShowRemainOtp(true);
        }
      })
      .catch((err) => {
        if(err?.response?.data?.message === "You have already submited a deletion request!"){
          setRequestAccepted(true)
          setIsLoading(false)
        }else{
          setIsUserNotFound(true);
          setIsLoading(false)
        }
      });
  };

  // 7006053103

  const verifyOtp = () => {
    newAxiosClientInterceptors
      .post(
        `/verify-sms-otp-mobile`,
        {
          mobile: "+91" + formik.values.phone,
          otp: otp,
        },
        { headers }
      )
      .then((res) => {
        toast["success"](res?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setModalOpen(true);
        setOtp("");
        setUserName(res.data.result.fullname);
        setUserEmail(res.data.result.email);
        setToSendId(res.data.result._id);
        setOpenOTP(false);
      })
      .catch((err) => {
        setOtpVerifiedError(true);
        setOtp("");
        toast["error"](err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const deletePartner = () => {
    newAxiosClientInterceptors
      .delete(
        `/delete-partner
    `,
        {
          headers,
          data: {
            id: toSendId,
          },
        }
      )
      .then((res) => {
        toast["success"](res?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        formik.resetForm({});
        if (localStorage.getItem("otp_remain")) {
          localStorage.setItem("otp_remain", 3);
        }
        setToSendId("");
        setOtp("");
        setUserName("");
        setUserEmail("");
        setModalOpen(false);
      })
      .catch((err) => {
        toast["error"](err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setUserName("");
        setUserEmail("");
      });
  };

  return (
    <>
      <div className="topContainer mt-[40px]">
        <div className="accountForm">
          <img className="logoImg" src={logo} alt="" />
          <h1 className="accountFormHeadline">
            Form for account deletion request
          </h1>
          <div className="partnerInformation">
            <div className="headerInfo">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M3 13L14 4L25 13"
                  stroke="#FBC316"
                  strokeWidth="2.4"
                  strokeMiterlimit="10"
                />
                <path
                  d="M12 25L12 19H16L16 25"
                  stroke="#FBC316"
                  strokeWidth="2.4"
                  strokeMiterlimit="10"
                />
                <path
                  d="M6 15L6 23C6 24.105 6.895 25 8 25L20 25C21.105 25 22 24.105 22 23V15"
                  stroke="#FBC316"
                  strokeWidth="2.4"
                  strokeMiterlimit="10"
                  strokeLinecap="square"
                />
              </svg>
              <h3>Partner Information</h3>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <h3>Your Registered Mobile Number</h3>
              <div className="inputNumber">
                <span className="">+91</span>
                <input
                  autoFocus
                  // ref={inputRef}
                  ref={inputRef => inputRef && inputRef.focus()}
                  type="number"
                  name="phone"
                  id="phone"
                  disabled={openOTP}
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                  onKeyDown={(event) => {
                    const value = event.target.value;
                    if (value.length >= 10 && event.keyCode !== 8) {
                      event.preventDefault();
                    }
                    return (
                      invalidChars.includes(event.key) && event.preventDefault()
                    );
                  }}
                />
              </div>
              {formik.touched.phone && formik.errors.phone && (
                <p className=" errorMess ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    fill="none"
                    viewBox="0 0 13 13"
                  >
                    <path
                      fill="#F99393"
                      d="M5.85 9.75h1.3v-3.9h-1.3v3.9zm.65-5.2a.629.629 0 00.463-.187.629.629 0 00.187-.463.629.629 0 00-.187-.463.629.629 0 00-.463-.187.629.629 0 00-.463.187.629.629 0 00-.187.463c0 .184.062.339.187.463a.629.629 0 00.463.187zm0 8.45c-.9 0-1.744-.17-2.535-.512a6.563 6.563 0 01-2.064-1.39 6.564 6.564 0 01-1.39-2.063A6.33 6.33 0 010 6.5c0-.9.17-1.744.512-2.535a6.564 6.564 0 011.39-2.064A6.564 6.564 0 013.964.511 6.33 6.33 0 016.5 0c.9 0 1.744.17 2.535.512.79.341 1.479.804 2.064 1.39a6.563 6.563 0 011.39 2.063c.34.79.511 1.636.511 2.535 0 .9-.17 1.744-.512 2.535a6.563 6.563 0 01-1.39 2.064 6.563 6.563 0 01-2.063 1.39A6.329 6.329 0 016.5 13z"
                    ></path>
                  </svg>
                  {formik.errors.phone}
                </p>
              )}

              {/* OTP Box  */}
              {openOTP && (
                <div className="otpContainer">
                  <div className="codeNotRecieved">
                  {timer === "0:00" && (
                   <>
                     <h4 className="mr-1">Didn’t get code yet?</h4>
                      <button className="resend cursor-pointer" type="submit">
                        Resend OTP
                      </button>
                   </>
                    )}
                    <CustomTimer
                      timer={timer}
                      starttime={120}
                      setTimer={setTimer}
                      startTimers={startTimers}
                    />
                  </div>
                  <OTPInput
                    value={otp}
                    onChange={(otp) => {
                      setOtpVerifiedError(false)
                      setOtp(otp);
                    }}
                    numInputs={4}
                    inputType="tel"
                    renderInput={(props) => <input {...props} />}
                    containerStyle={{
                      display: "flex",
                      justifyContent: "space-between",
                      // gap: "18px",
                    }}
                    inputStyle={{
                      width: "60px",
                      color: "#171A1F",
                      height: "55px",
                      margin: "8px 0px 0px 0px",
                      borderRadius: "4px",
                      boxShadow:
                        "0px 0px 1.19896px 0px rgba(23, 26, 31, 0.07), 0px 0px 2.39791px 0px rgba(23, 26, 31, 0.12)",
                      fontSize: "24px",
                      textAlign: "center",
                      fontFamily: "Mulish",
                      fontWeight: 700,
                      outline: "none",
                      border: `1px solid ${
                        otpVerifiedError ? "red" : "rgba(0, 0, 0, 0.3)"
                      }`,
                    }}
                  />
                </div>
              )}
              {!openOTP ? (
                <button className="flex justify-center" type="submit">
                  {isloading ? <Loader /> : "Get OTP"}
                </button>
              ) : (
                <div
                  onClick={handleModal}
                  className="confirmButton cursor-pointer"
                >
                  Confirm
                </div>
              )}
            </form>
            {showRemainOtp && (
              <span className="remainOtpAlert">
                Remaining OTP Attempts:{localStorage.getItem("otp_remain")}
              </span>
            )}
          </div>
        </div>

        <div className="divider"></div>
        <div className="stepsTopContainer">
          <h1 className="stepsHeader">
            Steps to deactivate or delete account from Partner App
          </h1>
          <div className="HomeContainer">
            {StepsData.map((data, index) => {
              return <StepComponent key={index} data={data} index={index} />;
            })}
          </div>
        </div>

        <Modal open={modalOpen} onClose={handleCancel}>
         <div> <AccountDeletion
            userName={userName}
            userEmail={userEmail}
            deletePartner={deletePartner}
            handleCancel={handleCancel}
          /></div>
        </Modal>

        <Modal open={isUserNotFound} onClose={userNotFoundModal}>
         <div> <UserNotFound
           userNotFoundModal={userNotFoundModal}
          /></div>
        </Modal>

        <Modal open={requestAccepted} onClose={requestAcceptModal}>
         <div> <RequestAccepted
           requestAcceptModal={requestAcceptModal}
          /></div>
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
};

export default HomeComponent;
