import React, { useEffect, useRef } from "react";

const CustomTimer = ({ timer, setTimer, startTimers, starttime }) => {
  const Ref = useRef(null);
  // otp timer
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    // setTimer("5:00");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + starttime);
    return deadline;
  };

  useEffect(() => {
    if (startTimers >= 1) {
      const timerStart = () => {
        clearTimer(getDeadTime());
      };
      timerStart();
    }

    return () => {
      if (Ref.current) {
        clearInterval(Ref.current);
      }
    };
  }, [startTimers]);

  return  timer!=="0:00" && <span className="ml-auto otpTimer"> &nbsp; {timer}</span>
};

export default CustomTimer;
